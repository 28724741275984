/* global graphql */

import React from 'react';
import TemplateWrapper from '../components/TemplateWrapper';
import CalloutUnderline from '../components/Callout/CalloutUnderline';
import Announcement from '../components/Announcement'

import WaitlistForm from '../components/WaitlistForm';

class WaitlistPage extends React.Component {

  render() {

    const { location } = this.props;

    return (
      <TemplateWrapper location={location}>
        <main className="Waitlist">
          <section className="Section Waitlist-header">
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-xs-14 col-md-12 col-md-offset-1">
                    <CalloutUnderline underlineColor="pink" lineOne="Be part of the Magic."/>
                  </div>
                  <div className="col-xs-14 col-md-10 col-md-offset-2 Waitlist-header-subtitle">
                    <Announcement direction="vertical" text="Magic developer alpha now available." text2="Sign up for updates and download Magic today." />
                    <svg width="26" height="52" viewBox="0 0 26 52" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-707-359H733v2029H-707z"/><g stroke="#FFF"><path d="M13 .478V51.34M25 39L13.746 52 1 39"/></g></g></svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Section Waitlist-form">
            <div className="container-fluid">
              <div className="wrapper">
                <div className="row">
                  <div className="col-xs-14 col-md-10 col-md-offset-2 col-lg-8 col-lg-offset-3">
                    <WaitlistForm />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </TemplateWrapper>
    );
  }

}

export default WaitlistPage;

