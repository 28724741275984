import React from 'react';
import { Form, Text, RadioGroup, Radio } from 'informed';
import 'whatwg-fetch';
import isValidEmail from 'validator/lib/isEmail';
import { b64EncodeUnicode, b64DecodeUnicode } from '../../common/conversion/string';
/*eslint-disable-next-line*/
import { getParams, toQueryString } from '../../common/url/param';
import { navigate, Link } from "gatsby";
import _ from 'lodash';

const EMAIL_PARAM = 'e';

class WaitlistForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isEmailValid: null,
      isFirstNameValid: null,
      isLastNameValid: null,
      isOpsSysValid: null,
      isHomeProValid: null,
      isCurrProvValid: null,
      isInterestedValid: null,
      isPrivacyChecked: null,
      isGroupOneValid: null,
      isGroupTwoValid: null,
      isGroupThreeValid: null
    };
    this.setFormApi = this.setFormApi.bind(this);

    this.prop = {
      emailContent: ''
    };

    this.waitlistFormSubmit = _.once(this.waitlistFormSubmit);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // eslint-disable-next-line
    return (nextState.isEmailValid !== this.state.isEmailValid || nextState.isLastNameValid !== this.state.isLastNameValid || nextState.isFirstNameValid !== this.state.isFirstNameValid || nextState.isOpsSysValid !== this.state.isOpsSysValid || nextState.isHomeProValid !== this.state.isHomeProValid || nextState.isCurrProvValid !== this.state.isCurrProvValid || nextState.isInterestedValid !== this.state.isInterestedValid || nextState.isPrivacyChecked !== this.state.isPrivacyChecked || nextState.isGroupOneValid !== this.state.isGroupOneValid || nextState.isGroupTwoValid !== this.state.isGroupTwoValid || nextState.isGroupThreeValid !== this.state.isGroupThreeValid);

  }

  /*eslint-disable-next-line*/
  componentWillMount() {
    const isBrowser = typeof window !== 'undefined';
    const getParams = isBrowser ? require('../../common/url/param') : undefined;
    if (getParams !== undefined) {
      let { [EMAIL_PARAM]: email } = getParams.getParams();
      email ? (this.emailContent = b64DecodeUnicode(email)) : this.emailContent = '';
    } else {
      this.emailContent = '';
    }
  }


  setFormApi(formApi) {
    this.formApi = formApi;
    this.formApi.setValues({ email: this.emailContent});
  }

  updateGroupState() {
    // eslint-disable-next-line
    const {isEmailValid, isLastNameValid, isFirstNameValid, isOpsSysValid, isHomeProValid, isCurrProvValid, isInterestedValid, isPrivacyChecked, isGroupOneValid, isGroupTwoValid, isGroupThreeValid } = this.state;

    if ( isEmailValid === true && isLastNameValid === true && isFirstNameValid === true) {
      this.setState({isGroupOneValid: true});
    } else if ( isEmailValid === false || isLastNameValid === false || isFirstNameValid === false ) {
      this.setState({isGroupOneValid: false});
    }
    if ( isOpsSysValid === true && isHomeProValid === true && isCurrProvValid === true && isInterestedValid === true  ) {
      this.setState({isGroupTwoValid: true});
    } else if ( isOpsSysValid === false || isHomeProValid === false || isCurrProvValid === false || isInterestedValid === false ) {
      this.setState({isGroupTwoValid: false});
    }
    if ( isPrivacyChecked === true ) {
      this.setState({isGroupThreeValid: true});
    } else if ( isPrivacyChecked === false ) {
      this.setState({isGroupThreeValid: false});
    }
  }


  render() {
    // eslint-disable-next-line
    const { isEmailValid, isLastNameValid, isFirstNameValid, isHomeProValid, isOpsSysValid, isCurrProvValid, isInterestedValid, isPrivacyChecked, isGroupOneValid, isGroupTwoValid, isGroupThreeValid } = this.state;

    this.updateGroupState();

    const validateEmail = value => {
      if (!value) {
        this.setState({isEmailValid: false});
        return "Email - Required field";
      } else if (!isValidEmail(value)) {
        this.setState({isEmailValid: false});
        return "Invalid email";
      } else {
        this.setState({isEmailValid: true});
        return null;
      }
    };
    const validateFirstName = value => {
      if (!value) {
        this.setState({isFirstNameValid: false});
        return 'First Name - Required field'
      } else {
        this.setState({isFirstNameValid: true});
        return null;
      }
    };
    const validateLastName = value => {
      if (!value) {
        this.setState({isLastNameValid: false});
        return 'Last Name - Required field'
      } else {
        this.setState({isLastNameValid: true});
        return null;
      }
    };

    const validateOpsSys = value => {
      if (!value) {
        this.setState({isOpsSysValid: false});
        return 'Required field'
      } else {
        this.setState({isOpsSysValid: true});
        return null;
      }
    };

    const validateHomePro = value => {
      if (!value) {
        this.setState({isHomeProValid: false});
        return 'Required field'
      } else {
        this.setState({isHomeProValid: true});
        return null;
      }
    };

    const validateCurrProv = value => {
      if (!value) {
        this.setState({isCurrProvValid: false});
        return 'Required field'
      } else {
        this.setState({isCurrProvValid: true});
        return null;
      }
    };

    const validateInterested = value => {
      if (!value) {
        this.setState({isInterestedValid: false});
        return 'Required field'
      } else {
        this.setState({isInterestedValid: true});
        return null;
      }
    };

    const validatePrivacy = value => {
      if (!value) {
        this.setState({isPrivacyChecked: false});
        return 'Please agree to Magic’s policies before joining the waitlist'
      } else {
        this.setState({isPrivacyChecked: true});
        return null;
      }
    };

    return (
      <div className="WaitlistForm row">
        <Form getApi={this.setFormApi} className="WaitlistForm-form">
          {({ formState }) => (
          <div>
          {/*<ComponentWithFormState />*/}
          <div className="WaitlistForm-section-1">
            <div>
              <Text onChange={this.inputChange} validateOnBlur validate={validateFirstName} className={"Input InputSecondary pink dark" + (isFirstNameValid === false ? " invalid" : "")} field="firstname" id="firstName" />
              <label className="Caption Caption-C3 uppercase color-pink" htmlFor="firstName">{formState.errors.firstname ? formState.errors.firstname : "First Name" }</label>
            </div>
            <div>
              <Text onChange={this.inputChange} validateOnBlur validate={validateLastName} className={"Input InputSecondary pink dark" + (isLastNameValid === false ? " invalid" : "")} field="lastname" id="lastName" />
              <label className={"Caption Caption-C3 uppercase color-pink" + (isLastNameValid === false ? " invalid" : "")} htmlFor="lastName">{formState.errors.lastname ? formState.errors.lastname : "Last Name" }</label>
            </div>
            <div>
              <Text onChange={this.inputChange} validateOnBlur validate={validateEmail} className={"Input InputSecondary pink dark emailInput" + (isEmailValid === false ? " invalid" : "")} value={this.emailContent} field="email" type="email" id="email" />
              <label className={"Caption Caption-C3 uppercase color-pink" + (isEmailValid === false ? " invalid" : "")} htmlFor="email">{formState.errors.email ? formState.errors.email : "Email" }</label>
            </div>
            <div className={"WaitlistForm-section-val pink" + (isGroupOneValid ? " valid" : " invalid")}>
              {isGroupOneValid ?
                <svg width="41" height="41" viewBox="0 0 41 41"><g fill="none" fillRule="evenodd"><path fill="#FF3BDB" d="M0 0h41v41H0z"/><path d="M16.557 30.66L33.05 10.838M16.662 31.454l-8.324-6.908" stroke="#FFF" strokeWidth="2"/></g></svg> :
                (isGroupOneValid === false ? <svg width="41" height="41" viewBox="0 0 41 41"><g stroke="#FF3BDB" strokeWidth="2" fill="none" fillRule="evenodd"><path d="M1 1h39v39H1zM8 8l25.486 25.486M33.486 8L8 33.486"/></g></svg> : null )
              }
            </div>
          </div>
          <div className="WaitlistForm-section-2">
            <div className="WaitlistForm-section-2-question">
              <p className="BodyText BodyText-B1 dark uppercase font-mono-regular WaitlistForm-section-2-question-title">What operating system do you use day-to-day?</p>
              <RadioGroup onChange={this.inputChange} validateOnChange validate={validateOpsSys} field="operating_system">
                <div className="WaitlistForm-custom-radio green">
                  <Radio value="macOS" id="radio-mac" />
                  <label htmlFor="radio-mac">macOS</label>
                </div>
                <div className="WaitlistForm-custom-radio green">
                  <Radio value="Windows 10" id="radio-windows" />
                  <label htmlFor="radio-windows">Windows 10</label>
                </div>
                <div className="WaitlistForm-custom-radio green">
                  <Radio value="Linux" id="radio-linux" />
                  <label htmlFor="radio-linux">Linux</label>
                </div>
                { formState.errors.operating_system && <div className="WaitlistForm-custom-radio-invalid"><p className="Caption Caption-C3 uppercase font-mono-regular">{formState.errors.operating_system}</p></div>}
              </RadioGroup>
            </div>
            <div className="WaitlistForm-section-2-question">
              <p className="BodyText BodyText-B1 dark uppercase font-mono-regular">Who is your home internet provider?</p>
              <Text onChange={this.inputChange} validateOnBlur validate={validateHomePro} className={"Input InputSecondary green dark" + (isHomeProValid === false ? " invalid" : "")} field="internetprovider" placeholder="" id="internetProvider" />
              <label className="Caption Caption-C3 uppercase color-green" htmlFor="internetProvider">{formState.errors.internetprovider ? formState.errors.internetprovider : "Home Internet Provider" }</label>
            </div>
            <div className="WaitlistForm-section-2-question">
              <p className="BodyText BodyText-B1 dark uppercase font-mono-regular">Who is your current cellular provider?</p>
              <Text onChange={this.inputChange} validateOnBlur validate={validateCurrProv} className={"Input InputSecondary green dark" + (isCurrProvValid === false ? " invalid" : "")} field="cellularprovider" placeholder="" id="cellularProvider" />
              <label className="Caption Caption-C3 uppercase color-green" htmlFor="cellularProvider">{formState.errors.cellularprovider ? formState.errors.cellularprovider : "Cellular Provider" }</label>
            </div>
            <div className="WaitlistForm-section-2-question twobytwo">
              <p className="BodyText BodyText-B1 dark uppercase font-mono-regular twobytwo-label">Are you interested in broadcasting Magic as a provider?</p>
              <RadioGroup onChange={this.inputChange} validateOnChange validate={validateInterested} field="interestedInBroadcasting">
                <div className="WaitlistForm-custom-radio green first">
                  <Radio value="I'm in" id="radio-in" />
                  <label htmlFor="radio-in">I'm in.</label>
                </div>
                <div className="WaitlistForm-custom-radio green second">
                  <Radio value="No thanks" id="radio-no" />
                  <label htmlFor="radio-no">No thanks.</label>
                </div>
                { formState.errors.interestedInBroadcasting && <div className="WaitlistForm-custom-radio-invalid"><p className="Caption Caption-C3 uppercase font-mono-regular">{formState.errors.interestedInBroadcasting}</p></div>}
              </RadioGroup>
            </div>
            <div className={"WaitlistForm-section-val green" + (isGroupTwoValid ? " valid" : " invalid")}>
              {isGroupTwoValid ?
                <svg width="41" height="41" viewBox="0 0 41 41"><g fill="none" fillRule="evenodd"><path fill="#50E3C2" d="M0 0h41v41H0z"/><path d="M16.557 30.66L33.05 10.838M16.662 31.454l-8.324-6.908" stroke="#FFF" strokeWidth="2"/></g></svg> :
                (isGroupTwoValid === false ? <svg width="41" height="41" viewBox="0 0 41 41"><g stroke="#50E3C2" strokeWidth="2" fill="none" fillRule="evenodd"><path d="M1 1h39v39H1zM8 8l25.486 25.486M33.486 8L8 33.486"/></g></svg> : null )
              }
            </div>
          </div>
          <div className="WaitlistForm-section-3">
            <p className="BodyText BodyText-B1 dark uppercase font-mono-regular">I agree to Magic's <Link className="Btn BtnText BtnText-orange dark font-regular" to="/privacy/">Privacy Policy</Link> and <Link className="Btn BtnText BtnText-orange dark font-regular" to="terms">Terms & Conditions</Link>.</p>
            <div className="WaitlistForm-section-3-radio-group">
              <RadioGroup onChange={this.inputChange} validateOnChange validate={validatePrivacy} field="isPrivacyChecked">
                <div className="WaitlistForm-custom-radio orange">
                  <Radio value="true" id="radio-true" />
                  <label htmlFor="radio-true">I agree</label>
                </div>
                { formState.errors.isPrivacyChecked && <div className="WaitlistForm-custom-radio-invalid"><p className="Caption Caption-C3 uppercase font-mono-regular">{formState.errors.isPrivacyChecked}</p></div>}
              </RadioGroup>
            </div>
            <div className={"WaitlistForm-section-val orange" + (isGroupThreeValid ? " valid" : " invalid")}>
              {isGroupThreeValid ?
                <svg width="41" height="41" viewBox="0 0 41 41"><g fill="none" fillRule="evenodd"><path fill="#FF4700" d="M0 0h41v41H0z"/><path d="M16.557 30.66L33.05 10.838M16.662 31.454l-8.324-6.908" stroke="#FFF" strokeWidth="2"/></g></svg> :
                (isGroupThreeValid === false ? <svg width="41" height="41" viewBox="0 0 41 41"><g stroke="#FF4700" strokeWidth="2" fill="none" fillRule="evenodd"><path d="M1 1h39v39H1zM8 8l25.486 25.486M33.486 8L8 33.486"/></g></svg> : null )
              }
            </div>
          </div>
            {(formState.invalid || !isGroupOneValid || !isGroupTwoValid || !isGroupThreeValid) ?
              <button onClick={()=>this.showValidationErrors(formState)} className={"BtnGhost BtnGhost-large purple uppercase color-white"} type="submit"><span>Sign Up</span></button> :
              <button onClick={()=>this.waitlistFormSubmit(formState)} className={"BtnGhost BtnGhost-large purple uppercase color-white"} type="submit"><span>Sign Up</span></button>
            }
            </div>
            )}
        </Form>
      </div>
    );

  }

  showValidationErrors(formState) {

  }

  waitlistFormSubmit(formState) {
    let formValues = formState.values;
    let values = {
      'firstname': formValues.firstname,
      'lastname': formValues.lastname,
      'email': formValues.email,
      'operating_system': formValues.operating_system,
      'internetprovider': formValues.internetprovider,
      'cellularprovider': formValues.cellularprovider,
      'interestedInBroadcasting': formValues.interestedInBroadcasting,
      'tos_approve': formValues.isPrivacyChecked,
      'privacy_constant': formValues.isPrivacyChecked,
      'privacy_message': "I AGREE TO MAGIC'S PRIVACY POLICY AND TERMS & CONDITIONS."
    };
    // Encode identifiable information
    let userId = b64EncodeUnicode(formValues.email);

    if (typeof window.analytics !== 'undefined') {
        
      window.analytics.alias(userId);
      window.analytics.identify(userId, values);

      window.analytics.track('Submitted Waitlist Form', {
        component: 'waitlistFormPage'
      });
      
      window.analytics.track('Signed up', {
        email: values.email
      });
    }

    // This posts to a lambda function, see: https://gitlab.teamhologram.ninja/magic/lambda/tree/master/waitlist
    fetch(`${process.env.GATSBY_WAITLIST_FORM_ENDPOINT}`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId,
        values
      })}).then(() => {
        navigate('/waitlist/success/');
      });
  }
}

export default WaitlistForm;
